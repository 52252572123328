import React, {useState, useEffect} from 'react';
import {Line} from 'react-chartjs-2'
import axios from 'axios'
import moment from 'moment'


const CoinGraph = ({id}) => {

    const [labels, setLabels] = useState([])
    const [prices, setPrices] = useState([])

    useEffect(() => {
        async function fetchData() {
            const data = await axios.get(`https://api.coingecko.com/api/v3/coins/${id}/market_chart?vs_currency=usd&days=1`).then(res => res.data)
            
            let allLabels = []
            let allPrices = []

            for (const price of data.prices) {
                
                const time = moment(price[0]).format("HH:MM:SS")
                allLabels.push(time);
                allPrices.push(price[1]);
            }

            setPrices(allPrices);
            setLabels(allLabels);
        }
        fetchData();
    })

    return (
        <>
            <Line
            options={{
                maintainAspectRatio: false,
                elements: {
                    point:{
                        radius: 0
                    },
                    fill: {
                        target: 'origin',
                        above: '#16697A',   // Area will be red above the origin
                        below: '#16697A'    // And blue below the origin
                      }
                }
            }}
            height="300"
            data={{
                labels: labels,
                datasets: [
                    {
                        label: 'Price',
                        backgroundColor: '#16697A',
                        
                        tension: 0,
                        data: prices,
                        fill: {
                            target: 'origin',
                            above: '#16697A',   // Area will be red above the origin
                            below: '#16697A'    // And blue below the origin
                          }
                    }
                ],
            }}
            />
        </>
    )
}

export default CoinGraph