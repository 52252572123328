import React, {useState, useEffect} from 'react';
import {useParams} from 'react-router-dom'
import axios from 'axios'
import CoinGraph from '../components/CoinGraph'
import { Link } from 'react-router-dom'

const Coin = (props) => {
  let { id } = useParams()
  const [coin, setCoin] = useState({})
  const [loader, setLoader] = useState(true);

  useEffect( () => {
    (async () => {
      const data = await axios.get(`https://api.coingecko.com/api/v3/coins/${id}`).then(res => res.data)
      setCoin({
        name: data.name,
        symbol: data.symbol.toUpperCase(),
        description: data.description['en'],
        currentPrice: data.market_data.current_price['usd'],
        marketCap: data.market_data.market_cap['usd'],
        marketCapRank: data.market_data.market_cap_rank,
        totalSupply: data.market_data.total_supply,
        circulatingSupply: data.market_data.circulating_supply,
        image: data.image.large,
        website: data.links.homepage[0],
        dayChange: data.market_data.price_change_24h
      })
      setLoader(false)
    })()
  })

  /**
   * Checks if Percentage is negitive or positive then set a pill color
   */
  function checkPriceChange(num) {
    if(Math.sign(num) === 1) {
      return <span className="badge rounded-pill bg-success">+{num}%</span>
    }

    return <span className="badge rounded-pill bg-danger">{num}%</span>
  }

  const MainContent  = (
    <>
      <div className="pt-3" style={{minHeight: 200}}>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><Link to="/">Home</Link></li>
            <li className="breadcrumb-item active" aria-current="page">{coin.name}</li>
          </ol>
        </nav>
        <div className="row g-3 flex">
          {/* Main Coin Info */}
          <div className="col-lg-4 order-lg-1 h-100  order-xs-2">
            <div className="card h100">
              <div className="card-body h100 row justify-content-between gy-3">
                <div className="col-6 col-lg-12 justify-content-center align-self-center">
                  <div>
                    <img height="40" style={{float: "left", paddingRight: 10}} alt={coin.id} src={coin.image}></img>
                    <p className=" h4">{coin.name}</p>
                  </div><br></br>
                  <div>
                    <span className="list-inline-item badge bg-secondary rounded-pill">{coin.symbol}</span>
                    <a href={coin.website}>{coin.website}</a>
                  </div>
                </div>
                <div className="col-6 col-lg-12 text-end text-lg-start align-self-top">
                  <div>
                    <small>{coin.name} Price ({coin.symbol})</small>
                    <div className="justify-content-center">
                      {checkPriceChange(coin.dayChange)}
                      <p className="h3">${coin.currentPrice}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Coin Graph */}
          <div className="col-lg-8 order-lg-2  order-xs-1">
            <div className="card">
              <div className="card-body">
              <CoinGraph id={id}/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-5 pb-4" style={{minHeight: 200}}>
        <div className="row">
          <div className="col">
            <p className="my-2 lead">About {coin.name}</p>
            <div dangerouslySetInnerHTML={{__html: coin.description}} />
          </div>
        </div>
      </div>
    </>
  )


  return (
    <div className="h-100">
      {loader ? <span>Loading</span> : MainContent}
    </div>
  )
};

export default Coin;
