import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Home from './pages/Home';
import Coin from './pages/Coin';
import AppNavbar from './components/AppNavbar'
import 'bootstrap/dist/css/bootstrap.css'
import './App.css'


function App() {
  return (
    <Router>
      <AppNavbar />
      <div className="bg-light d-flex flex-column">
        <div className="container  h-100">
          <Switch>
            <Route path="/coin/:id">
              <Coin />
            </Route>
            <Route path="/">
              <Home />
            </Route>
          </Switch>
        </div>
      </div>
    </Router>
  );
}

export default App;
