import React from 'react';
import { Link } from 'react-router-dom'
import logo from '../assets/bitwatcher.png'

export default function AppNavbar() {

  return (
    <nav className="navbar navbar-light bg-white py-3 px-2">
      <div className="container-fluid">
        <Link className="navbar-brand app-bar" to="/">
          <img height="45" alt="BitWatcher-Logo" src={logo} />
          <span className="ml-3 app-bar-name">BITWATCHER</span>
        </Link>
      </div>
    </nav>
  );
}
