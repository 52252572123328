import React, {useState, useEffect} from 'react';
import axios from 'axios'
import Loader from '../components/Loader'

const Home = () => {


  const [cryptoList, setCryptoList] = useState([])

  useEffect(() => {
    async function fetchData() {
      const data = await axios.get('https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=100&page=1')
      .then((res) => {
          return res.data
      })
      setCryptoList(data.sort((a, b) => (a.market_cap_rank > b.market_cap_rank) ? 1 : -1))
    }

    fetchData();
  })

  function checkPriceChange(num) {
    if(Math.sign(num) === 1) {
      return <span className="badge rounded-pill bg-success">+{num}%</span>
    }

    return <span className="badge rounded-pill bg-danger">{num}%</span>
  }

  const CryptoItem = ({coin}) => {
    return (
      <tr key={coin.id} className="align-middle">
        <th><small className="badge bg-secondary rounded-pill">{coin.market_cap_rank}</small></th>
        <th scope="row">
          <a href={`/coin/${coin.id}`} className="coin-list-link">
            <img className="coin-list-link-img" height={30} alt={coin.id} src={coin.image} ></img>
            <span className="coin-list-link-name">{coin.name}</span>
          </a>
        </th>
        <td>${coin.current_price}</td>
        <td>{checkPriceChange(coin.price_change_percentage_24h.toFixed(2))}</td>
        <td>{checkSupply(coin.total_supply)}</td>
        <td>${coin.market_cap}</td>
      </tr>
    )
  }

  const checkSupply = (supply) => {
    if(supply === null) return "No Supply Cap"
    return supply
  }

  const ListCrypto = () => {

    return (
      <>
      <table class="table table-borderless">
        <thead>
          <tr>
            <th>#</th>
            <th scope="col">Name</th>
            <th scope="col">price</th>
            <th scope="col">24h %</th>
            <th scope="col">Total Supply</th>
            <th scope="col">Market Cap</th>
          </tr>
        </thead>
        <tbody>
  
            {cryptoList.map((coin) => <CryptoItem style={{marginLeft: 'auto', marginRight: 'auto'}} coin={coin} />)}
          
        </tbody>
        
      </table>
      
      </>
    )
    
  }

  return (
    <div className="bg-light">
      <div className="container-fluid py-3">
        <h3 className="mt-4 mb-5 text-center">Top 100 Cryptocurrency Prices by Market Cap.</h3>
        <div className="card coin-list-card mx-auto">
          <div className="card-body d-flex" style={{overflow: 'auto'}}>
            {cryptoList.length === 0 ? <Loader className="align-content-stretch align-content-center flex-wrap" /> : <ListCrypto />}
          </div>
        </div>
      </div>
    </div>
    
  )
};

export default Home;
