import React from 'react'

const Loader = () => {
    return (
      <div className="spinner-grow loader align-items-center">
        <span className="visually-hidden">Loading...</span>
      </div>
    )
}

export default Loader